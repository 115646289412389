export default {
  currentHost: null,
  playerList: [],
  submissionList: [],
  roundNumber: 1
}

export const PlayerModel = {
  username: 'foo',
  ipAddress: null,
  hand: []
}

export const punCards = [
  "There will be blood",
"the wolf of wall street",
"the west wing",
"cry me a river",
"dallas cowboys",
"the young & the restless",
"daft punk",
"moby dick",
"wheel of fortune",
"who wants to be a millionaire",
"the white stripes",
"dane cook",
"dancing queen",
"there's no time like the present",
"adobe photoshop",
"ace ventura: pet detective",
"a wrinkle in time",
"abraham lincoln",
"a quiet place",
"the foo fighters",
"the fresh prince of bel air",
"a whole new world",
"a heartbreaking work of staggering genius",
"the empire strikes back",
"super mario brothers",
"the legend of zelda",
"back to the future",
"might morphin' power rangers",
"the fellowship of the ring",
"the giving tree ",
"avengers: endgame",
"the godfather",
"the grand budapest hotel",
"the grapes of wrath",
"just the way you are",
"katniss everdeen",
"kellog's frosted mini-wheats",
"kentucky fried chicken",
"kim jong un ",
"kinder eggs",
"king kong",
"kings of leon",
"the backstreet boys",
"back to the future",
"another one bites the dust",
"atlanta braves",
"another brick in the wall",
"amazon prime",
"all the light we cannot see ",
"all night long ",
"all about that bass ",
"alice in chains",
"albus dumbledore",
"life of pi ",
"little miss sunshine",
"lord of the flies",
"encyclopedia brown",
"los angeles lakers",
"luke skywalker",
"anakin skywalker",
"mad max: fury road",
"marky mark & the funky bunch",
"matchbox twenty",
"the beach boys",
"the dark knight rises",
"the da vinci code",
"the cat in the hat ",
"the call of the wild",
"the blair witch project",
"the black eyed peas",
"the big sick",
"the big bang theory",
"vaccines requiring cold storage",
"28 days later",
"die hard ",
"dawn of the dead ",
"breaking bad ",
"macy's thanksgiving day parade",
"born this way",
"a star is born",
"boston red sox",
"new england patriots",
"the lion king",
"tiger king",
"the queen's gambit",
"microsoft office",
"playstation 5",
"the hunger games: mockingjay",
"the hunchback of notre dame",
"huckleberry finn",
"the green mile",
"wu tang clan",
"yellow brick road",
"hips don't lie",
"heard it through the grapevine",
"head, shoulders, knees and toes",
"mary had a little lamb",
"harry potter",
"han solo",
"guns n' roses",
"top gun",
"ariana grande",
"toy story",
"groundhog day",
"greenday",
"green eggs & ham",
"good will hunting",
"gone with the wind",
"tickle me elmo",
"to kill a mockingbird",
"give me liberty or give me death",
"george foreman grill",
"garbage pail kids",
"cabbage patch kids",
"rolling in the deep",
"reese witherspoon",
"red hot chili peppers",
"twenty-one pilots",
"van halen",
"watership down",
"the lion, the witch, & the wardrobe",
"west side story",
"the book of mormon",
"wet hot american summer",
"where the wild things are",
"winnie the pooh",
"one flew over the cuckoo's nest",
"the brady bunch",
'the walking dead',
'the breakfast club',
'old macdonald had a farm',
'row, row, row your boat',
"dick clark's rockin' new year's eve",
"american idol",
"RuPaul's drag race",
"indiana jones and the temple of doom",
"Reese's peanut butter cups",
"Pirates of the Carribean",
""
]