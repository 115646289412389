import React, {useState} from 'react'
import ReactDOM from 'react-dom';

import { Router, Link } from "@reach/router"

import PunsFrame from 'components/puns/PunsFrame'
import SkullsFrame from 'components/skulls/SkullsFrame'
import NounsFrame from 'components/nouns/NounsFrame'

import Login from './Login'

function GameLinks() {
  return (
    <PageLayout>
      <div className="row">
        <div className="col-md-4 offset-md-4 col-sm-12 text-center">
          <h3> Games </h3>
          <Link to="puns">Puns of Anarchy</Link><br/>
          <Link to="skulls">Skulls &amp; Roses</Link><br/>
          <Link to="nouns">Bag of Nouns</Link><br/>
        </div>
      </div>
    </PageLayout>
  )
}

export function PageLayout({children, user, logo, className}) {
  return (
    <div className={`mainForm ${className}`}>
      <h2 className="text-left">
        { logo && <img src={logo} style={{marginLeft: 'calc(50% - 50px)'}}/> }
        <span style={{marginLeft: '3em'}}>{ user ? user.name : ''}</span>
      </h2>
      { children }
    </div>
  )
}


export default function HomePage() {
  return (
    <Router>
      <PunsFrame path="puns"/>
      <SkullsFrame path="skulls"/>
      <NounsFrame path="nouns"/>
      <GameLinks default/>
    </Router>
  )
}
