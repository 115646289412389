import React, {useState, useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import _clone from 'lodash/clone'
import _shuffle from 'lodash/shuffle'
import _pull from 'lodash/pull'
import _isEqual from 'lodash/isEqual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import punsLogo from 'images/punslogo.png'
import { PageLayout } from 'components/HomePage'
import Login from 'components/Login'
import GameModel, { punCards } from './GameModel'
import reloadIcon from 'images/reload.png'
import SubmissionList from './SubmissionList'
import AdminView from './AdminView'

export default function LoginFrame() {
  let [user, setUser] = useState(null)
  if (!user) {
    return <PageLayout logo={punsLogo}><Login setUser={setUser} newUserPath="puns/new_user"/></PageLayout>
  }
  return <GameFrame user={user}/>
}


function GameFrame({user}) {
  let [deck, setDeck] = useState(_shuffle(_clone(punCards)))
  let [hand, setHand] = useState([])
  let [gameState, setGameState] = useState({submissions: [], users: [], round: 1})
  let [isFirstLoad, setIsFirstLoad] = useState(true)
  let [pickedCard, setPickedCard] = useState(null)
  let [isSubmitted, setIsSubmitted] = useState(false)

  const textArea = useRef(null)

  function drawCards(numCards) {
    let newDeck = _clone(deck)
    let newCards = newDeck.slice(0, numCards)
    let newHand = _clone(hand)
    setDeck(newDeck.slice(numCards))
    return newCards
  }

  function replaceCard(cardName) {
    let replaceIndex = hand.indexOf(cardName)
    let newHand = _clone(hand)
    const newCard = drawCards(1)
    newHand[replaceIndex] = newCard[0]
    setHand(newHand)
  }

  function submit(text) {
    fetch('/api/puns', 
          {
            method: 'POST', 
            "Content-Type": "application/json", 
            body: JSON.stringify({user: user.name, text: textArea.current.value})
          })
    setIsSubmitted(true)
  }

  function pickCard(item, event) {
    if (event.target.closest('button')) {
      return;
    }
    setPickedCard(item)
    textArea.current.value = `\n[[ ${item} ]]`
    setTimeout(() => {
      textArea.current.focus()
      textArea.current.selectionEnd = 0
    }, 300)
  }

  function fetchGameState() {
    fetch('/api/puns').then((result) => {
      result.json().then((resultJson) => {
        if (!_isEqual(resultJson, gameState)) {
          setGameState(resultJson)
        }
      })
    })
  }

  function pollGameState() {
    fetchGameState()
    setTimeout(() => {
      pollGameState()
    }, 1000)
  }

  useEffect(() => {
    const newHand = drawCards(6)
    pollGameState()
    setHand(newHand)
  }, [])

  useEffect(() => {
    textArea.current.value = ''
    setPickedCard(null)
    setIsSubmitted(false)
  }, [gameState.round])


  let submitButton = <button className="btn btn-primary" onClick={ submit }>Submit</button>
  if (isSubmitted) {
    submitButton = (<button className="btn btn-secondary" onClick={ submit }>
      <FontAwesomeIcon icon={faCheck}/>
    </button>)
  }

  return (
    <PageLayout user={user} logo={punsLogo}>
      <div>
        <div className="handContainer row">
          { hand.map( (item, index) => {
            return (
              <div key={index} className="col-lg-4 col-md-6 col-sm-6">
                <div className="cardContainer">
                  <div className={item === pickedCard ? 'handCard picked' : 'handCard'} onClick={(event) => pickCard(item, event)}>
                    { item.toUpperCase() }
                    <button className="btn" onClick={() => replaceCard(item)}>
                      <FontAwesomeIcon icon={faSyncAlt}/>
                    </button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className="row">
          <div className="col-md-4 offset-md-4 col-sm-12 text-center">
            <div className="textAreaContainer">
              <textarea ref={textArea} type="text" rows="3" cols="40" placeholder="Your hilarious pun..."/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            {submitButton}
          </div>
        </div>
        <br/>
        <SubmissionList submissions={gameState.submissions} users={gameState.users} roundNumber={gameState.round}/>
        <br/><br/>
        { user.admin && 
          <AdminView submissions={gameState.submissions}/>
        }
      </div>
    </PageLayout>
  )
}