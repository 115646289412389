import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import _find from 'lodash/find'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSyncAlt } from '@fortawesome/free-solid-svg-icons'

export default function SubmissionList({submissions, users, roundNumber}) {
  return (
    <div>
      <h4>Noun submission status:</h4>
      <div className="row">
        <SubmitterChecks submissions={submissions} users={users}/>
      </div>
    </div>
  )
}

function SubmitterChecks({users, submissions}) {
  const userSubmissionList = users.map((user) => {
    return {user: user, isSubmitted: !!_find(submissions, {user: user.name}), nSubmitted: submissions.filter(s => s.user === user.name).length}
  })

  return (
    <div className="col-md-4 offset-md-4 userList">
      {userSubmissionList.map((userEntry, index) => {
        return (
          <div className="userEntry" key={index}>
            <span className="user">{userEntry.user.name} </span>
            <span className="submitted">
              { userEntry.nSubmitted }
            </span>
          </div>
        )
      })}
    </div>
  )
}
