import React, {useState} from 'react'
import ResponseList from './ResponseList'

export default function AdminView({submissions}) {
  let [isShowResponses, setIsShowResponses] = useState(false)

  function startNewRound() {
    fetch('/api/puns/new_round', {method: 'POST'})
    setIsShowResponses(false)
  }

  function resetAll() {
    fetch('/api/puns/reset_all', {method: 'POST'})
  }

  function showResponses() {
    fetch('/api/puns/show_responses', {method: 'POST'})
    setIsShowResponses(!isShowResponses)
  }

  return (
   <div className="adminPanel">
     <div className="row">
        <div className="col-md-2 adminButtons">
          <button className="btn btn-primary" onClick={showResponses }>{isShowResponses ? 'Hide' : 'Show'} Responses</button><br/>
          <button className="btn btn-secondary" onClick={startNewRound}>Start new round</button><br/>
          <button className="btn btn-secondary" onClick={resetAll}>Reset all</button><br/>
        </div>
        <div className="col-md-10">
          { isShowResponses && <ResponseList submissions={submissions}/> }
          { !isShowResponses && <span> waiting... </span> }
        </div>
      </div>
    </div>


  )

}