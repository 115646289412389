import React, {useState, useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import _clone from 'lodash/clone'
import _shuffle from 'lodash/shuffle'
import _pull from 'lodash/pull'
import _isEqual from 'lodash/isEqual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSyncAlt, faRecycle } from '@fortawesome/free-solid-svg-icons'

import nounsLogo from 'images/nounslogo.png'
import GameModel from './GameModel'
import { PageLayout } from 'components/HomePage'
import reloadIcon from 'images/reload.png'
import SubmissionList from './SubmissionList'
import AdminView from './AdminView'
import ResponseList from './ResponseList'
import Login from 'components/Login'

export default function LoginFrame() {
  let [user, setUser] = useState(null)
  if (!user) {
    return <PageLayout logo={nounsLogo}><Login setUser={setUser} newUserPath="puns/new_user"/></PageLayout>
  }
  return <GameFrame user={user}/>
}

const buttonStyle = {
  padding: '1em',
  width: '100%',
  background: '#5fc40e',
  color: '#fff',
  fontWeight: 'bold',
  cursor: 'pointer',
  borderRadius: '5px',
  border: 'none',
  marginBottom: '1em',
  display: 'block'
}

const secondaryButtonStyle = Object.assign({}, buttonStyle, {background: '#777'})

export function GameFrame({user}) {
  let [hand, setHand] = useState([])
  let [gameState, setGameState] = useState({submissions: [], users: [], round: 0, noun_hat: []})
  let [isFirstLoad, setIsFirstLoad] = useState(true)
  let [activeNoun, setActiveNoun] = useState(null)
  let [isSubmitted, setIsSubmitted] = useState(false)
  let [turnEndTime, setTurnEndTime] = useState(null)
  let nounsPerPlayer = 6
  let [isShowResponses, setIsShowResponses] = useState(false)


  const textArea = useRef(null)

  function submit(text) {
    fetch('/api/nouns',
          {
            method: 'POST',
            "Content-Type": "application/json",
            body: JSON.stringify({user: user.name, text: textArea.current.value.trim()})
          })
    setIsSubmitted(true)
  }

  function drawNoun() {
    let shuffledNounHatContents = _shuffle(_clone(gameState.noun_hat))
    let currentNoun = shuffledNounHatContents[0]
    fetch(`/api/nouns/draw_noun?noun=${currentNoun}`, {method: 'POST'})
    setActiveNoun(currentNoun)
    console.log(currentNoun)
    return currentNoun
  }

  function putBack() {
    fetch(`/api/nouns/put_back?noun=${activeNoun}`, {method: 'POST'})
    setActiveNoun(null)
  }

  function startTurn() {
    let t = new Date()
    t.setSeconds(t.getSeconds() + 60)
    drawNoun()
    setTurnEndTime(t)
  }

  function stopTurn() {
    setTurnEndTime(null)
  }

  function fetchGameState() {
    fetch('/api/nouns').then((result) => {
      result.json().then((resultJson) => {
        // we always update gamestate to force a rerender, so the countdown timer
        // does not need a separate polling operation
        setGameState(resultJson)
      })
    })
  }

  function pollGameState() {
    fetchGameState()
    setTimeout(() => {
      pollGameState()
    }, 1000)
  }

  useEffect(() => {
    pollGameState()
  }, [])

  useEffect(() => {
    if (gameState.round == 0) {
      textArea.current.value = ''
    }
    setActiveNoun(null)
    setIsSubmitted(false)
  }, [gameState.round])


  function startNewRound() {
    fetch('/api/nouns/new_round', {method: 'POST'})
    setIsShowResponses(false)
  }

  function resetAll() {
    fetch('/api/nouns/reset_all', {method: 'POST'})
  }

  function showResponses() {
    fetch('/api/nouns/show_responses', {method: 'POST'})
    setIsShowResponses(!isShowResponses)
  }


  function arrayToList(someArray) {
    return <div><ul>{someArray.map( (item, index) => <li key={index}>{item}</li>)}</ul></div>
  };

  let submitButton = <button style={buttonStyle} onClick={ submit }>Submit</button>
  let turnButton = <button style={buttonStyle} onClick={startTurn}>Start my turn</button>
  if (turnEndTime) {
    const secondsLeft = Math.round(Math.max(0, (turnEndTime - new Date())/1000))
    const textColor = secondsLeft > 0 ? '#5fc40e' : '#c45f0e'
    turnButton = (
      <div className="text-center">
        <span style={{color: textColor, fontSize: '2em', fontWeight: 'bold'}}>{secondsLeft} seconds</span>
        <button onClick={stopTurn} style={Object.assign({}, secondaryButtonStyle, {width: '3em', display: 'inline', float: 'right'})}>
          <FontAwesomeIcon icon={faRecycle}/>
        </button>
      </div>
    )
  }
  return (
    <div className={`mainForm`}>
      <h2 className="text-left" style={{background: '#fff', height: '50px', color: '#ccc'}}>

        <img src={nounsLogo} style={{marginTop: '0px', marginRight: '2em'}} height="50px"/>
        BAG of NOUNS
        <span style={{marginLeft: '3em', color: '#bbb', float: 'right'}}>{ user ? user.name : ''}</span>
      </h2>
      <h4 className="text-center">Round {gameState.round}

        <span style={{fontStyle: 'italic', color: '#999', fontWeight: 'normal', marginLeft: "1em"}}>
          ({gameState.noun_hat.length} nouns in hat)
        </span>
      </h4>

      { gameState.round == 0 &&
        <>
          <div className="row">

            <div className="offset-md-4 col-md-4 col-sm-12 text-center">
              <div className="textAreaContainer">
                <textarea ref={textArea} type="text" rows="2" cols="80" placeholder="Enter a hilarious noun phrase here" />
                {submitButton}
              </div>
            </div>
          </div>
          <SubmissionList submissions={gameState.submissions} users={gameState.users} roundNumber={gameState.round}/>
        </>}
      <br/>
      { gameState.round != 0 &&
        <div className="row">
          <div className="offset-md-4 col-md-4 col-sm-12 text-center">
            { turnEndTime &&
              <>
                <div style={{background: '#fff', padding: '1em'}}>
                  <h3>{activeNoun}</h3>
                </div>
                <br/>

                <button style={buttonStyle} onClick={drawNoun}>
                  Draw noun
                </button>
                { activeNoun &&
                  <button style={secondaryButtonStyle} onClick={putBack}>Put back</button>
                }
              </>
            }
            { turnButton }
          </div>
        </div>
      }
      { user.admin && <div className="adminPanel">

        <div className="row">
          <div className="offset-md-4 col-md-4 adminButtons col-sm-12">
             <h4>Admin buttons</h4>
             <button style={secondaryButtonStyle} onClick={startNewRound}>Start new round</button><br/>
             <button style={secondaryButtonStyle} onClick={resetAll}>Reset all</button><br/>
             <button style={buttonStyle} onClick={showResponses }>{isShowResponses ? 'Hide' : 'Show'} Full Noun List</button><br/>
          </div>
          <div className="offset-md-4 col-md-4 col-sm-12">
            { isShowResponses && arrayToList(gameState.noun_hat) }
          </div>
        </div>
      </div>}
    </div>
  )
}
