import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleDoubleLeft, faAngleRight, faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'
import _shuffle from 'lodash/shuffle'
import _clone from 'lodash/clone'

export default function ResponseList({submissions}) {
  const [shownSubmissions, setShownSubmissions] = useState(_shuffle(_clone(submissions)))
  const [responseIndex, setResponseIndex] = useState(0)
  let responseContent = <span style={{fontSize: '20px', fontStyle: 'italic'}}>no responses to show</span>
  if (responseIndex < shownSubmissions.length && responseIndex >= 0) {
    responseContent = (
      <div className="shownResponse">
        <pre style={{fontFamily: 'Hevetica'}}>{shownSubmissions[responseIndex].text}</pre>
      </div>
    )
  }
  return (
    <div className="responseView">
      { responseContent }
      <div style={{fontSize: '16px'}}>{responseIndex + 1} of {shownSubmissions.length}</div>
      <button className="btn btn-secondary" onClick={() => { setResponseIndex(0) }} >
        <FontAwesomeIcon icon={faAngleDoubleLeft}/>
      </button>
      <button className="btn btn-secondary" onClick={() => { setResponseIndex(responseIndex - 1) }}><FontAwesomeIcon icon={faAngleLeft}/></button>
      <button className="btn btn-secondary" onClick={() => { setResponseIndex(responseIndex + 1) }}><FontAwesomeIcon icon={faAngleRight}/></button>
      <button className="btn btn-secondary" onClick={() => { setResponseIndex(shownSubmissions.length - 1) }} ><FontAwesomeIcon icon={faAngleDoubleRight} /></button>
    </div>
  )
}