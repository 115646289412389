import React, {useState, useEffect} from 'react'
import _isEqual from 'lodash/isEqual'
import _clone from 'lodash/clone'
import _sortBy from 'lodash/sortBy'
import _find from 'lodash/find'

import skullLogo from 'images/skullLogo.png'
import cardBack from 'images/cardBack.png'
import flower1 from 'images/flower1.png'
import flower2 from 'images/flower2.png'
import flower3 from 'images/flower3.png'
import flower4 from 'images/flower4.png'
import skull1 from 'images/skull1.png'
import skull2 from 'images/skull2.png'
import skull3 from 'images/skull3.png'
import { PageLayout } from 'components/HomePage'
import Login from 'components/Login'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFan, faSkull, faTrash, faEye, faCogs } from '@fortawesome/free-solid-svg-icons'

const userColorScale = ["#1f77b4","#ff7f0e","#2ca02c","#d62728","#9467bd","#8c564b","#e377c2","#7f7f7f","#bcbd22","#17becf"]
const flowerArray = [flower1, flower2, flower3, flower4]
const skullArray = [skull1, skull3, skull2]

export default function LoginFrame() {
  let [user, setUser] = useState(null)//{name: 'devon'})
  if (!user) {
    return <PageLayout logo={skullLogo}><Login setUser={setUser} newUserPath="skulls/new_user"/></PageLayout>
  }
  return <SkullFrame user={user}/>
}

function renderCardBack(index, userIndex, overlap, cardSize='100px') {
  let marginLeft = '0px'
  let marginTop = '0px'
  let color = userColorScale[userIndex]
  if (index != 0) {
    if (overlap) {
      marginLeft = '-85px'
    } else {
      marginTop = '-35px'
    }
  }
  return <img src={cardBack} key={index} style={{height: cardSize, width: cardSize, borderRadius: '50px', border: `2px solid ${color}`, marginLeft, marginTop}}/>
}

function renderCardFront(card, index, userIndex, overlap, cardSize='100px') {
  let marginLeft = '0px'
  let marginTop = '0px'
  let color = userColorScale[userIndex]
  if (index != 0) {
     if (overlap) {
       marginLeft = '-85px'
     } else {
       marginTop = '-35px'
     }
  }

  const imgArray = card === 'R' ? flowerArray : skullArray
  const imgSrc = imgArray[userIndex % imgArray.length]
  return <img src={imgSrc} key={index} style={{height: cardSize, width: cardSize, borderRadius: '50px', border: `2px solid ${color}`, marginTop, marginLeft}}/>
}


function SkullFrame({user}) {
  const [gameState, setGameState] = useState({users: [], round_number: 1})
  const currentUser = _find(gameState.users, {name: user.name})

  function playCard(card) {
    fetch(`/api/skulls/play_card?username=${user.name}&card=${card}`, {method: 'POST',  "Content-Type": "application/json"})
    .then((result) => {
      result.json().then((resultJson) => {
        setGameState(resultJson)
      })
    })
  }

  function revealTop() {
    fetch(`/api/skulls/reveal_top?username=${user.name}`, {method: 'POST',  "Content-Type": "application/json"})
    .then((result) => {
      result.json().then((resultJson) => {
        setGameState(resultJson)
      })
    })
  }

  function trashCard(card) {
    fetch(`/api/skulls/remove_card?username=${user.name}&card=${card}`, {method: 'POST',  "Content-Type": "application/json"})
    .then((result) => {
      result.json().then((resultJson) => {
        setGameState(resultJson)
      })
    })
  }

  function newRound() {
    fetch(`/api/skulls/new_round`,  {method: 'POST',  "Content-Type": "application/json"}).then((result) => {
      result.json().then((resultJson) => {
        setGameState(resultJson)
      })
    })
  }

  function newGame() {
    fetch(`/api/skulls/new_game`,  {method: 'POST',  "Content-Type": "application/json"}).then((result) => {
      result.json().then((resultJson) => {
        setGameState(resultJson)
      })
    })
  }


  function resetAll() {
    fetch(`/api/skulls/reset_all`,  {method: 'POST',  "Content-Type": "application/json"}).then((result) => {
      result.json().then((resultJson) => {
        setGameState(resultJson)
      })
    })
  }

  function fetchGameState() {
    fetch(`/api/skulls?username=${user.name}`).then((result) => {
      result.json().then((resultJson) => {
        if (!_isEqual(resultJson, gameState)) {
          setGameState(resultJson)
        }
      })
    })
  }

  function pollGameState() {
    fetchGameState()
    setTimeout(() => {
      pollGameState()
    }, 1000)
  }

  useEffect(() => {
    pollGameState()
  }, [])

  return (
    <div className={`mainForm`}>
      <h2 className="text-left" style={{background: '#999', height: '50px'}}>
        <img src={skullLogo} style={{marginLeft: 'calc(50% - 50px)', marginTop: '0px'}} height="50px"/>
        <span style={{marginLeft: '3em', color: '#fff'}}>{ user ? user.name : ''}</span>
      </h2>
      <div className="skullFrame row">
        <div className="col-md-4 offset-md-4 col-sm-12">
          <div style={{maxWidth: '100vw'}}>
              {_sortBy(_clone(gameState.users), ['name']).map((userToDraw, userIndex) => {
                const isSelf =  user.name === userToDraw.name
                return (
                  <React.Fragment key={userIndex}>
                    { renderUser(userToDraw, userIndex, isSelf) }
                    { isSelf && renderButtons(userToDraw, playCard, revealTop, trashCard) }
                  </React.Fragment>
                )
              })}
          </div>
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" style={{float: 'right'}}>
              <FontAwesomeIcon icon={faCogs}/> Admin
            </button> 
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" onClick={newRound}>New round</a>
               <a className="dropdown-item" >  &nbsp; --- &nbsp;   </a>
               <a className="dropdown-item" onClick={newGame}>New game</a>
              <a className="dropdown-item" >  &nbsp; --- &nbsp;   </a>
              <a className="dropdown-item" onClick={resetAll}>Reset all</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


function renderButtons(user, playCard, revealTop, trashCard) {
  let showPlaySkull = user.hand.indexOf('S') >= 0 && user.revealed.length === 0
  let showPlayRose = user.hand.indexOf('R') >= 0 && user.revealed.length === 0
  let showRevealTop = user.played.length > 0
  let showTrashCard = user.hand.length > 0
  
  return (
    <div key="buttons" style={{display: 'flex', marginBottom: '1em'}}>
      <div style={{width: '40%'}}>
        { showPlayRose && 
          <button className="btn" style={{background: '#cc4466', color: '#fff'}} onClick={() => playCard('R')}>
            Play <FontAwesomeIcon icon={faFan}/>
          </button> 
        }
        { showPlaySkull && 
          <button className="btn" style={{background: '#222', color: '#fff', marginLeft: '1em'}} onClick={() => playCard('S')}>
            Play <FontAwesomeIcon icon={faSkull}/>
          </button> 
        }
      </div>
      <div style={{width: '30%'}}>
        { showRevealTop && 
          <button className="btn" style={{background: '#fff', color: '#229', marginLeft: '1em'}} onClick={revealTop}>
            Show <FontAwesomeIcon icon={faEye}/>
          </button> 
        }
      </div>
      { showTrashCard && 
        <div style={{width: '30%'}} className="dropdown">
          <button className="btn dropdown-toggle" data-toggle="dropdown" style={{background: '#ccc', color: '#559', maxWidth: '3em', borderRadius: '2em', border: '1px solid #666', float: 'right'}}>
            <FontAwesomeIcon icon={faTrash}/>
          </button> 
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" onClick={() => trashCard('R')}>Trash <FontAwesomeIcon icon={faFan}/></a>
            <a className="dropdown-item" onClick={() => trashCard('S')}>Trash <FontAwesomeIcon icon={faSkull}/></a>
          </div>
        </div>
      }
    </div>
  )
}


function renderUser(user, userIndex, isSelf) {
  return (
    <div key={userIndex} style={{paddingBottom: '0.5em', display: 'flex', paddingLeft: '0.5em'}}>
      <div style={{width: '20%', color: userColorScale[userIndex], fontWeight: 'bold', paddingTop: '40px'}}>{user.name} {isSelf && <span style={{fontWeight: 'normal', color: '#333'}}><br/> (you)</span> }</div>
      <div style={{width: '20%', paddingLeft: '0.5em', overflowX: 'hidden', whiteSpace: 'nowrap'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          { user.hand.map((card, index) => {
            if (!isSelf) {
              return renderCardBack(index, userIndex, false, '50px')  
            } else {
              return renderCardFront(card, index, userIndex, false, '50px')
            }
          })}
        </div>
      </div>
      <div style={{width: '30%', borderLeft: '2px dashed #bbb', borderRight: '2px dashed #bbb', paddingLeft: '0.5em', overflowX: 'hidden', whiteSpace: 'nowrap' }}>
        { user.played.map((card, index) => {
          return renderCardBack(index, userIndex, true)
        })}
      </div>
       <div style={{width: '30%', minWidth: '25%', paddingLeft: '0.5em', overflowX: 'hidden', whiteSpace: 'nowrap'}}>
        { user.revealed.map((card, index) => {
          return renderCardFront(card, index, userIndex, true)
        })}
      </div>
    </div>
  )
}