import React, {useState, useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Router, Link } from "@reach/router"


export default function Login({ setUser, newUserPath}) {
  let passwordField = useRef(null)
  let usernameField = useRef(null)

  function submit() {
    const password = passwordField.current.value
    const username = usernameField.current.value
    if (password.startsWith('party') && username) {
      const user = {
        name: username,
        admin: password === 'partytipton'
      }
      fetch(`api/${newUserPath}`,
           {
              method: 'POST', 
              "Content-Type": "application/json", 
              body: JSON.stringify(user)
            })
      setUser(user)
    }
  }
  return (
    <div className="row">
      <div className="col-md-4 offset-md-4 col-sm-12 offset-sm-0">
        <form className="text-center" onSubmit={submit}>
          <div className="form-group">
            <input placeholder="Your name"  className="form-control" type="text" ref={usernameField} size="20"/>
          </div>
          <div className="form-group">
            <input placeholder="password" className="form-control" type="password" ref={passwordField} size="20"/>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">Log in</button>
          </div>
        </form>
      </div>
    </div>
  )
}
